import Vue from "vue";
import Vuex from "vuex";

import htmlClass from "./htmlclass.module";
import config from "./config.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isMobile: false,
    myId: '',
    myInfo: null,
    serviceChargeInfo: null,
    liveEventList: null,
    eventList: null,
    myBlockList: [],
    employeeList: []
  },
  mutations: {
    set(state, [variable, value]) {
      state[variable] = value
    }
  },
  modules: {
    htmlClass,
    config
  }
});
