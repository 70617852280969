import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';

Vue.use(Router);
Vue.use(Meta, {
  refreshOnceOnNavigation: true
});

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: 'home',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: 'home',
          name: 'home',
          component: () => import('@/view/pages/Home')
        },
        {
          path: 'live_events',
          name: 'live_events',
          component: () => import('@/view/pages/events/LiveEvents')
        },
        {
          path: 'e/:eventParam/:promoCode?',
          name: 'event_detail',
          component: () => import('@/view/pages/events/EventDetail')
        },
        {
          path: 'vip/:eventId/:time',
          name: 'vip_ticket_link',
          component: () => import('@/view/pages/events/VIPTicketLink')
        },
        {
          path: 'u/:userName',
          name: 'user_detail',
          component: () => import('@/view/pages/users/UserDetail')
        },
        {
          path: 'c/:channelId',
          name: 'channel',
          component: () => import('@/view/pages/etc/Channel')
        },
        {
          path: 'profile',
          name: 'Profile',
          component: () => import('@/view/pages/users/EditProfile')
        },
        {
          path: 'my_events/:type?',
          name: 'my_events',
          component: () => import('@/view/pages/events/MyEvents')
        },
        {
          path: 'dashboard/:eventId',
          name: 'event_dashboard',
          component: () => import('@/view/pages/events/EventDashboard')
        },
        {
          path: 'add_event',
          name: 'add_event',
          component: () => import('@/view/pages/events/EditEvent')
        },
        {
          path: 'edit_event/:eventId',
          name: 'edit_event',
          component: () => import('@/view/pages/events/EditEvent')
        },
        {
          path: 'my_tickets',
          name: 'my_tickets',
          component: () => import('@/view/pages/etc/DownloadApp')
        },
        {
          path: 'cookie_policy',
          name: 'cookie_policy',
          component: () => import('@/view/pages/privacy/CookiePolicy')
        },
        {
          path: 'imprint',
          name: 'imprint',
          component: () => import('@/view/pages/privacy/Imprint')
        },
        {
          path: 'terms',
          name: 'terms',
          component: () => import('@/view/pages/privacy/TermsService')
        },
        {
          path: 'privacy',
          name: 'privacy',
          component: () => import('@/view/pages/privacy/PrivacyPolicy')
        },
        {
          path: 'business_agreement',
          name: 'business_agreement',
          component: () => import('@/view/pages/privacy/BusinessUserAgreement')
        },
        {
          path: 'become_an_organizer',
          name: 'become_an_organizer',
          component: () => import('@/view/pages/etc/BecomeOrganizer')
        },
        {
          path: 'contact_us',
          name: 'contact_us',
          component: () => import('@/view/pages/etc/ContactUs')
        },
        {
          path: 'help_center',
          name: 'help_center',
          component: () => import('@/view/pages/etc/ContactUs')
        },
        {
          path: 'about',
          name: 'about',
          component: () => import('@/view/pages/etc/About')
        },
        {
          path: 'contact',
          name: 'contact',
          component: () => import('@/view/pages/etc/Contact')
        },
        {
          path: 'jobs',
          name: 'jobs',
          component: () => import('@/view/pages/etc/Jobs')
        },
        {
          path: 'jobs_list',
          name: 'jobs_list',
          component: () => import('@/view/pages/etc/JobList')
        },
        {
          path: 'jobs_detail/:index',
          name: 'job_detail',
          component: () => import('@/view/pages/etc/JobDetail')
        },
        {
          path: 'sponsorship',
          name: 'sponsorship',
          component: () => import('@/view/pages/etc/Sponsorship')
        },
        {
          path: 'community_guidelines',
          name: 'community_guidelines',
          component: () => import('@/view/pages/etc/CommunityGuidelines')
        },
        {
          path: 'edit_drink/:eventId',
          name: 'edit_drink',
          component: () => import('@/view/pages/drinks/EditDrink')
        },
        {
          path: 'drink_dashboard/:eventId',
          name: 'drink_dashboard',
          component: () => import('@/view/pages/drinks/DrinkDashboard')
        }
      ]
    },
    {
      path: '/',
      redirect: 'privacy_policy',
      component: () => import('@/view/layout/PrivacyLayout'),
      children: [
        {
          path: 'terms_service',
          name: 'terms_service',
          component: () => import('@/view/pages/privacy/TermsService')
        },
        {
          path: 'privacy_policy',
          name: 'privacy_policy',
          component: () => import('@/view/pages/privacy/PrivacyPolicy')
        },
        {
          path: 'business_user_agreement',
          name: 'business_user_agreement',
          component: () => import('@/view/pages/privacy/BusinessUserAgreement')
        }
      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: () => import('@/view/layout/AuthLayout'),
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/view/pages/auth/Login')
        },
        {
          path: 'select_user_type',
          name: 'select_user_type',
          component: () => import('@/view/pages/auth/SelectUserType')
        },
        {
          path: 'welcome',
          name: 'welcome',
          component: () => import('@/view/pages/auth/VerifyEmailPhone')
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('@/view/pages/auth/Register')
        },
        {
          path: 'reset_password',
          name: 'reset_password',
          component: () => import('@/view/pages/auth/VerifyEmailPhone')
        },
        {
          path: 'business_agreement/:redirect',
          name: 'business_agreement_for_register',
          component: () => import('@/view/pages/privacy/BusinessUserAgreement')
        }
      ]
    },
    {
      path: '/payment/stripe_return',
      name: 'stripe_return',
      component: () => import('@/view/pages/payment/StripeReturn')
    },
    {
      path: '/payment/confirm/:status?',
      name: 'payment_confirm',
      component: () => import('@/view/pages/payment/Confirm')
    },
    {
      path: '/payment/checkout/:transaction?',
      name: 'payment_checkout',
      component: () => import('@/view/pages/payment/Checkout')
    },
    {
      path: '/payment/drink_checkout/:transaction',
      name: 'payment_drink_checkout',
      component: () => import('@/view/pages/payment/DrinkCheckout')
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('@/view/pages/Error404')
    }
  ]
});