import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/core/services/store';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueMobileDetection from 'vue-mobile-detection';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueGtag from 'vue-gtag';
import VueCarousel from 'vue-carousel';

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { ModalPlugin } from 'bootstrap-vue';

import linkify from 'vue-linkify';

Vue.config.productionTip = false;
Vue.use(VueMobileDetection);
Vue.use(VueLoading);
Vue.use(VueToast, {
  position: 'top-right',
  duration: 5000
});
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCn8G2fO3QSiysygWOisdaVm4YyIiTsNpo',
    libraries: ['places']
  },
  installComponents: true
});
Vue.use(VueGtag, {
  config: {
    id: 'G-T8BTMH28D2'
  }
});
Vue.use(VueCarousel);
Vue.directive('linkified', linkify);
Vue.use(ModalPlugin);

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n';
import vuetify from '@/core/plugins/vuetify';
import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/highlight-js';
import '@/core/plugins/inline-svg';
import '@/core/plugins/apexcharts';
import '@/core/plugins/metronic';
import '@mdi/font/css/materialdesignicons.css';

Vue.mixin({
  data: function() {
    return {
      RESULT_SUCCESS: 1000,
      RESULT_UNKNOWN_ERROR: -1,
      RESULT_INVALID_PARAM: -1000,
      RESULT_INCORRECT_PASSWORD: -1001,
      RESULT_USER_DELETED: -1002,
      RESULT_USER_BLOCKED: -1005,
      RESULT_DUPLICATED_EMAIL: -1011,
      RESULT_DUPLICATED_PHONE: -1012,
      RESULT_DUPLICATED_USERNAME: -1013,
      RESULT_DUPLICATED_TRANSACTION: -1014,
      RESULT_TICKET_ALREADY_USED: -1015,
      RESULT_TICKET_ALREADY_REFUNDED: -1016,
      RESULT_NOT_EXIST: -1020,
      RESULT_EMAIL_NOT_EXIST: -1021,
      RESULT_PHONE_NOT_EXIST: -1022,
      RESULT_USERNAME_NOT_EXIST: -1023,
      RESULT_USER_NOT_EXIST: -1024,
      RESULT_NOT_OWNER: -1030,
      RESULT_NOT_ORGANIZER: -1031,
      RESULT_USER_UNAPPROVED: -1032,
      RESULT_WITHDRAW_HAS_PENDING: -1041,
      RESULT_WITHDRAW_OVER_BALANCE: -1042,
      RESULT_WITHDRAW_MAX_HALF: -1043,
      RESULT_WITHDRAW_LIVE_EVENT: -1044,
      PREFIX_FROM_SYSTEM: 'FromSystem:',
      USER_NORMAL: 0,
      USER_ORGANIZER: 1,
      USER_GUEST: 2,
      SUFFIX_SOCIAL_WITHOUT_EMAIL: '@social.without.email',
      MAX_QUANTITY_LIMIT: 15,
      TEST_PREFIX: 'Test-',
      GOOGLE_PLAY_URL: 'https://play.google.com/store/apps/details?id=com.eventbox.de',
      APP_STORE_URL: 'https://apps.apple.com/app/eventbox-nb/id1669690995',
      FOR_MA: false
    }
  }
});

router.beforeEach((to, from, next) => {
  next();
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app');

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDSVS0zmdro2sBDa6SuwyqGvBkWvHVZYOo",
  authDomain: "eventbox-c7c8f.firebaseapp.com",
  projectId: "eventbox-c7c8f",
  storageBucket: "eventbox-c7c8f.appspot.com",
  messagingSenderId: "1011853757251",
  appId: "1:1011853757251:web:dce0221c2c209db7063df6",
  measurementId: "G-BCWXJB1YPP"
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const functions = getFunctions(firebaseApp, 'europe-west1');